.ag-theme-alpine {
    margin-top:30px;
}

p.header {
    font-weight: 700;
}

.wbs-studio-grid {
    display:inline-block;
    vertical-align: top;
    width:80%;
}

.row-details {
    display:inline-block;
    width: 20%;
    padding:20px;
}

.keywords-display {
    display:block;
    width: 100%
}