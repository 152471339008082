.dot-low {
    height: 15px;
    width: 15px;
    background-color: #9ddeff;
    border-radius: 50%;
    display: inline-block;
  }

  .dot-med-low {
    height: 15px;
    width: 15px;
    background-color: #2696f8;
    border-radius: 50%;
    display: inline-block;
  }

  .dot-med {
    height: 15px;
    width: 15px;
    background-color: #ffa45e;
    border-radius: 50%;
    display: inline-block;
  }

  .dot-med-high {
    height: 15px;
    width: 15px;
    background-color: #f87204;
    border-radius: 50%;
    display: inline-block;
  }

  .dot-high {
    height: 15px;
    width: 15px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
  }

  .mapping {
    display: inline-block;
    padding-left:10px;
  }

  .MuiDataGrid-cell {
    overflow:hidden;
  }

  .list {
    height: 440px;
  }