.project-wizard-card {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
}

button.projectWizardBtn {
    background: linear-gradient(180deg, #009CD1, #007EB3);
    color: #ffffff;
    text-transform: capitalize;
    padding: 10px 20px;
    margin-top: auto;
}

.left-card-wizard {
    background-color: #F4F7FA;
    /*width: 60%;*/
    padding: 0px 16px;
}

.selectAProject {
    width: 100%;
}

.left-card-wizard svg.MuiSvgIcon-root,
.right-card-wizard svg.MuiSvgIcon-root {
 
    margin-right: 10px;
}

.right-card-wizard {
    background-color: #F4F7FA;
    padding: 0px 16px 15px;
    display: flex;
    flex-direction: column;
}

.form-field.control.project.dropdown {
    margin-top: 20px;
}

.react-chatbot-kit-chat-input {
    border-top: none !important;
}

#alert-dialog-title {
    font-weight: 700;
    font-size: 1.7rem;
}


@media only screen and (max-width: 1024px) {
    .project-wizard-card {
        grid-template-columns: 1fr;
    }
}