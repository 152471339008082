.sidenav {
    width: 270px;
    transition: width 0.3s ease-in-out;
    min-height: 100vh;
    background-color: rgb(50, 50, 60);
    padding-top: 28px;
}

.sidenavClosed {
    composes: sidenav;
    transition: width 0.3s ease-in-out;
    width: 60px;
}

.sideitem {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.sideitem a.MuiTypography-root, .sideitem a.MuiLink-root {
    color: white;
    text-decoration:none;
}

.linkText {
    padding-left: 16px;
}

.linkTextClosed {
    composes: linkText;
    visibility: hidden;
}

.sideitem:hover {
    background-color: #244f7d1c;
}

.menuBtn {
    align-self: center;
    align-self: flex-start;
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 20px;
}