li.react-search-box-dropdown-list-item {
    color:#000000;
}

input.sc-gswNZR::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

.react-search-box-dropdown {
    position: absolute;
    top: 50%;
    left: 0%;
    font-size: 12px;
    color: white;
    width:500px;
    z-index:2;
    /*transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);*/
}

.header .MuiSvgIcon-root {
    margin: 5px;
}

#primary-notifications-account-menu div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    width: 300px;
    padding: 0px;
    border-radius: 10px;
}

#primary-notifications-account-menu div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper a {
    text-decoration:none;
    padding: 20px;
    display:block;
}

/* chatbox styling */

.react-draggable {
    min-width: 400px;
}
/*.react-chatbot-kit-chat-container {
}
*/
.react-chatbot-kit-chat-inner-container {
    min-width:360px;
}

.react-chatbot-kit-chat-header {
    background-color: cadetblue;
}

.react-chatbot-kit-chat-input-container {
    margin-top:10px;
}

.MuiDialogContent-root .react-chatbot-kit-chat-message-container {
    overflow:hidden;
    overflow-y:scroll;
}

.react-chatbot-kit-chat-input {
    margin-top:10px;
}
/*
.react-chatbot-kit-chat-input-form {
}

.react-chatbot-kit-chat-input::placeholder {
}

.react-chatbot-kit-chat-btn-send {
}

.react-chatbot-kit-chat-btn-send-icon {
}

.react-chatbot-kit-chat-bot-message-container {
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
}

.react-chatbot-kit-chat-bot-message {
}

.react-chatbot-kit-chat-bot-message-arrow {
}

.react-chatbot-kit-chat-bot-loading-icon-container {
}

.chatbot-loader-container {
}

#chatbot-loader #chatbot-loader-dot1 {
}

#chatbot-loader #chatbot-loader-dot2 {
}

#chatbot-loader #chatbot-loader-dot3 {
}

.react-chatbot-kit-error {
}

.react-chatbot-kit-error-container {
}

.react-chatbot-kit-error-header {
}

.react-chatbot-kit-error-docs {
}*/