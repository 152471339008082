.graph.headers h2 {
    display: inline;
}

.graph.headers h2.mitigated {
    margin-left:70%;
}

text.recharts-text.bar-label tspan{
    background-color:white;
    border:#007EB3 1px solid;
}