html,
body,
#root {
    height: 100%;
    padding: 0;
    margin: 0;
}
/* .gantt_layout_root {
    height: 1000px !important;
    padding: 0;
    margin: 0;
} */

.gantt-container {
    height: calc(100vh - 50px - 200px);
}