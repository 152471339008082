.project-list {
    margin: 20px 0;
}

.btns-top-list {
    margin-bottom: 10px ;
}

.btns-top-list > button:first-child {
    margin-right: 10px;
}