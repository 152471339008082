.tab-detail header.MuiPaper-root {
    background-color: white;
    box-shadow: none;
}

table.MuiTable-root {
    background-color: #EDF9FF;
    border: 1px #007EB3 solid;
}

.project-tabs {
    display:flex;
    width:100%;
    margin: 10px 0;
}

.project-details .leftCol {
    width: 100% !important;
    float:none !important;
}

.awsControlListProject {
    margin-top:20px !important;
}


