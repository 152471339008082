.compliance-cap-poam-status {
 width: 50%;
 float:left;
 display:inline;   
}

.compliance-cap-poam-criticality {
    width: 50%;
    float:right;
    display:inline;   
   }