p.description {
    color:#727685;
}

.remaining {
    color: #10A466;
    display:inline;
}

.subscription table.MuiTable-root {
    background-color: white;
    border:none;
}

.subscription td.MuiTableCell-body {
    font-weight: 700;
    font-size:16px;
}

.subscription button {
    text-transform: capitalize;
    margin: 20px;
}

