.aws_control.list { 
    min-height:600px;
    min-width:1000px;
}

.Failed, .failed {
    color: red;
}

.Critical {
    color: rgb(122, 0, 0);
}

.unknown, .notavailable {
    color:dodgerblue;
}

.passed, .aws_control.list .Low  {
    color:rgb(20, 200, 20);
    background-color:transparent !important;
    padding: 5px;
}

.aws_control.list .Medium  {
    color:rgb(216, 118, 12);
    background-color:transparent !important;
    padding: 5px;
}

.aws_control.list .High {
    background-color:transparent !important;
    color: red;
    padding: 5px;
}

.security-score {
    font-size:2em;
    padding: 0px 20px 10px 20px;
}

.fail {
    color:red;
}

.average {
    color: chocolate;
}

.pass {
    color:rgb(20, 200, 20);
}

.security-controls {
    font-size: 12px;
}