.highlight {
   background-color: blue;
   color: white;
}

.panel.calendar {
   display: block;
   /*height:400px;*/
}

.custom-calendar {
   max-width: 450px;
   border: 1px solid #CCCCCE;
}

button.react-calendar__tile {
   width: 50px;
   height: 50px;
   margin: 10px;
   flex:none !important; 
}

.react-calendar__month-view__weekdays__weekday {
   text-transform: capitalize;
   text-align:center;
   flex: none !important;
   width: 60px;
}

abbr[title] {
   text-decoration: none !important;
   color: #AAAAAC;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button, 
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
   color: #AAAAAC;
}

.react-calendar__navigation {
   border-bottom: 1px solid #CCCCCE;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
   font-weight: 700;
}

.react-calendar {
   width: 100%;
   margin-top: 50px;
}

.calendar-priority-High {
   background-color: #E45455;
   border-radius: 5px;
}

.calendar-priority-Immediate {
   background-color: #5E0A41;
   border-radius: 5px;
   color: #ffffff;
}

.calendar-priority-Medium {
   background-color: #FDAF67;
   border-radius: 5px;
}

.calendar-priority-Low {
   background-color: lightgreen;
   border-radius: 5px;
}