.exceptions, .assessments {
    display:inline-block;
    width:400px;
}

.control-mitigation {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.control-class, .control-family, .control-phase, .control-status {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}