.subscription-plans .cards {
    display:inline-block;
    margin: 0px 0px 20px 20px;
    text-align:left;
}

.subscription-plans .cards .card-right {
    text-align:left;
    display:block;
    height:40px;
    margin-top: 0px;
}

.costing {
    height:auto;
    vertical-align: baseline;
}

.blue-select {
    border: 4px solid var(--med-light-blue, #007EB3);
}

.headsNum .MuiInputBase-root {
    width: 100px;
}