.risk-cards {
    display:inline-block;
    width:100%;
    color: white;
}

.risk-cards p {
    color: #ffffff;
}

.risk-count, .risk-analysis, .average-risk {
    width: 30%;
    display:inline-block;
    vertical-align: top;
    margin-right: 20px;
    padding: 10px;
}

.risk-count {
    background: #0074A6;
    height:130px;
}

.risk-analysis {
    background: #28A6DB;
    height:130px;
}

.average-risk {
    background: #EDF9FF;
    height:130px;
    color: #0074A6;
}

div#ag-grid-container {

}
div#chartsDisplay {
    width:70%;
    margin-top:-700px;
    margin-left:10px;
}

div#myChart1 {
    display: inline-block;
    /*position:fixed;*/
    width:50%;
    z-index:200;
    margin-left:0px;
}

div#myChart2 {
    display: inline-block;
    /*position:fixed;*/
    width:50%;
    z-index:200;
    margin-left:0px;
}

.ag-dialog {
    display: inline;
}

/*.ag-popup-child {
    top: 100px !important;
}*/

@media only screen and (max-width: 600px) {
    .risk-count, .risk-analysis, .average-risk {
        width: 100%;
        display:inline-block;
        vertical-align: top;
        /*margin-right: 20px;
        padding: 10px;*/
    }

    .risks-mitigated-over-time .apexcharts-canvas {
        width: 100% !important;
    }

    .wrapper {
        padding: 0px;
    }

    .main {
        padding: 0px;
    }
}