.normalLineHeight {
    line-height: normal;
    overflow-wrap: break-word;
    white-space: normal !important;
  }
h1.dashboard-title {
    text-transform: capitalize;
}

/*.MuiDataGrid-cellContent {
    white-space: normal !important;
}*/

.Immediate {
    background-color: #5E0A41;
    color: #ffffff;
    text-transform: uppercase;
    padding: 5px 10px; 
    border-radius: 5px;
 }


.High {
    background-color: #E45455;
    color: #ffffff;
    text-transform: uppercase;
    padding: 5px 10px; 
    border-radius: 5px;
 }

 .Medium {
    background-color: #FDAF67;
    text-transform: uppercase;
    padding: 5px 10px; 
    border-radius: 5px;
 }

 .Low {
    background-color: lightgreen;
    text-transform: uppercase;
    padding: 5px 10px; 
    border-radius: 5px;
 }

 .Mui-even.MuiDataGrid-row {
    background-color: #F4F7FA;
 }