.cc-details input {
    padding:10px;
    display:block;
    margin: 10px;
    width:95%;
    border: 1px solid rgba(137, 137, 137, 0.878);
}

form#payment-form {
    padding: 5px;
}

.billing-details .MuiFormControl-root {
    width:95%;
    display:block;
}

.billing-details .MuiInputBase-root {
    width: 95%;
    margin: 10px 0px;
    padding: 3px;
    font-size:14px;
}

select {
    font-size:14px;
    padding:10px;
    margin: 10px;
}