.css-ablsu8-MuiPaper-root-MuiCard-root {
    display:inline-block;
    width:300px;
    height:200px;
    vertical-align:top;
}

.cards {
    display:inline-block;
    /*width:30%;*/
    vertical-align: top;
    border: 1px solid #0074A6;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
    height: 220px;
    width: 320px;
}

#cards .MuiCard-root {
    box-shadow:none;
}

.system-administration button {
    margin: 0px;
    padding: 5px 12px;
    text-align: center;
    background-color: #007EB3;
}
.MuiCardActions-root {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1255) {
    .cards {
        /*width: 100%;*/

    }
}