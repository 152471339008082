

.gantt_message_area .gantt-error {
  display:none !important;
}

.gantt_modal_box.gantt-excel-form {
  min-width:100%;
}

.ganttChartContainer, .gantt_here {
  width:100%;
  min-height: 1000px;
  height: 100%;
}

html, body {
  padding: 0px;
  margin: 0px;
  height: 100%;
}

#gantt_here {
  width:100%;
  height: 800px;
  height:100vh;
}

.gantt_grid_scale .gantt_grid_head_cell,
.gantt_task .gantt_task_scale .gantt_scale_cell {
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.resource_marker{
  text-align: center;
}
.resource_marker div{
  width: 28px;
  height: 28px;
  line-height: 29px;
  display: inline-block;

  color: #FFF;
  margin: 3px;
}
.resource_marker.workday_ok div {
  border-radius: 15px;
  background: #51c185;
}

.resource_marker.workday_over div{
  border-radius: 3px;
  background: #ff8686;
}

.folder_row {
  font-weight: bold;
}

.gantt_task_cell.week_end {
  background-color: #e8e8e87d;
}

.gantt_task_row.gantt_selected .gantt_task_cell.week_end {
  background-color: #e8e8e87d !important;
}


.group_row,
.group_row.odd,
.gantt_task_row.group_row{
  background-color: rgba(232, 232, 232, 0.6);
}

.owner-label{
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 25px;
  background: #e6e6e6;
  color: #6f6f6f;
  margin: 0 3px;
  font-weight: bold;
}
