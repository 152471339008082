/* react flowchart node-js */
.diagram-component {
  width: 100%;
  min-height: 700px;
  border: none;
  background-color: #eeeeee;
}

.paletteDiv {
  width: 100%;
  min-height: 100px;
  border: none;
  margin: auto;
}

.addChildBtn {
  text-align: center;
}

.addChildBtn button {
  text-transform: capitalize;
  text-align: center;
}

/* workflow conditional form */
.workflow .MuiDialog-container .MuiPaper-root {
  max-width: 1000px;
}

/* CSS for the traditional context menu */
.menu {
  display: none;
  position: absolute;
  opacity: 0;
  margin: 0;
  padding: 8px 0;
  z-index: 999;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  list-style: none;
  background-color: #ffffff;
  border-radius: 4px;
}

.menu-item {
  display: block;
  position: relative;
  min-width: 60px;
  margin: 0;
  padding: 6px 16px;
  font: bold 12px sans-serif;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.menu-item::before {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.menu-item:hover::before {
  opacity: 0.04;
}

.menu .menu {
  top: -8px;
  left: 100%;
}

.show-menu,
.menu-item:hover>.menu {
  display: block;
  opacity: 1;
}

.conditional.select {
  float:left;
}

.conditional.text {
  float:right;
}

.relationship {
  display:block;
  height: 160px;
  background-color: #eeeeee;
  padding: 1em;
  margin-bottom: 1em;
}

.conditional {
  width: 50%;
}

.buttons {
  width: 100%;
  float:left;
  display:block;
  height:50px;
  padding:10px;
}

.conditional .MuiSelect-select, .conditional .MuiOutlinedInput-input {
  background-color: #ffffff;
}

button.deleteBtn {
  margin: 10px;
}