.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
  width: 100%;
}


body {
  margin: 0;
  padding: 0;
  background-color: #F2F2F2;
}

.App {
  display: flex;
  /*min-height: 1100px;*/
}

main { 
  padding: 10px;
  background-color: #fff;
  flex-grow: 1;
}

.wrapper {
  padding: 20px;
  width: 100%;
}

.form-field {
  padding: 10px 0;
}

li.MuiBreadcrumbs-li {
  text-transform: capitalize;
}

a.MuiLink-underlineNone {
  margin:5px;
  padding:5px;
}

svg {
  vertical-align: text-bottom;
}

.panel {
  padding: 20px 0px;
}

.form-field label {
  display:block;
}

.MuiDialogActions-root.create-edit-dialog {
  justify-content: flex-end;
}

header.MuiPaper-root {
  background-color: #007EB3;
}

.sideitem a {
  color: white;
  text-decoration:none;
}

.details-view {
  max-width:75vw;
}

h1 {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0.14289847016334534px;
  text-align: left;
}

h1 a {
  text-decoration: none;
}

h1:after {
  background: linear-gradient(77deg,#0578a8,#7bb7d1 47%,#fff);
  content: "";
  height: 1px;
  left: 0;
  position: relative;
  display:inline-block;
  width: 100%;
}

.loaderContainer {
  position: fixed;
  top: 0px;
  bottom: 0;
  height: 100%;
  width: 100%;
  right: 0;
  background: #efefef;
  z-index: 1;
  display: flex;
}
.loader {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100px;
  height: 100px;
  background: transparent;
  padding: 20px;
}

.wrapper .MuiBox-root {
  padding: 0;
}

table.MuiTable-root {
  margin-top: 20px;
}

main .MuiTableContainer-root {
  width: 100%;
  overflow-x: overlay;
  box-shadow: none;
}

td.title {
  font-weight:bold;
}

td.MuiTableCell-root {
  border:none;
  /*padding: 5px;*/
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 40px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #353535;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007EB3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #005B90;
}

li.MuiBreadcrumbs-li a {
  text-decoration: none;
}

ul {
  margin: 0px;
  padding: 0px;
}

#reactSchedulerOutsideWrapper {
  position:static;
  margin-top:100px;
  inset: 100px;
}

.leftCol {
  width: 70%;
  float: left;
}

.rightCol {
  width: 30%;
  float: right;
  overflow: hidden;
  padding: 10px;
}

/* styling for dialogs */
.MuiDialog-container .MuiPaper-root {
  max-width:fit-content;
}

.css-1hdyuqk-MuiPaper-root-MuiAlert-root {
  margin-top:50px;
}

.App a {
  text-decoration: none;
}

.wrapper p {
  color: #727685;
}

/*button.MuiButtonBase-root {
  margin: 10px;
}*/

ul {
  margin-left:20px;
}

/* Task calendar */
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  text-wrap: wrap;
}