.top-btn-group button.edit-project-btn {
    display:inline;
}

.top-btn-group .watch-switch {
    margin-left:auto;
}

.top-btn-group button.history-btn {
    display:inline;
}

.top-btn-group {
    display:inline-block;
    /*min-height:20px;*/
}

.document.list {
    margin-top:10px !important;
}
