.register-wrapper {
    display: block;
    /*flex-direction: column;*/
    margin: 0 auto;
    /*align-items: center;*/
    text-align: left;
    position: relative;
    width: 1000px;
    /*min-height: 800px;*/
    /*left: 200px;*/
    top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    padding:40px;
}

.register-wrapper  h1 {
    text-align:left;
    padding-left: 0px;
}

#root {
    background-color: #F2F2F2;
    /*min-height:950px;*/
}

.login-here a {
    color: #005B90;
    text-decoration:none;
    text-align: center;
    font-size:17px;
    line-height:25px;
    font-weight: 400;
}

button.re-send {
    margin-top: 20px;
    width:504px;
    height:55px;
    /* blue gradient */
    background: linear-gradient(180deg, #b9e0f7 0.51%, #ffffff 100%);
    /* blue */
    border: 1px solid #62bef4;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 20px;
    color:#005B90;
}

.adminContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: fit-content;
}

@media only screen and (max-width: 1024px) {
    .adminContainer {
        grid-template-columns: 1fr;
    }
}